import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./chat.css";

// Utility function to generate a unique user ID (e.g., UUID)
const generateUserId = () => {
  return `user-${Math.random().toString(36).substr(2, 9)}`;
};

const Chat: React.FC = () => {
  const [isChatVisible, setChatVisible] = useState(false);
  const [isChatMovedLeft, setChatMovedLeft] = useState(false);
  const [messages, setMessages] = useState<
    { text: string; sender: "user" | "bot" }[]
  >([]);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [actionOptions, setActionOptions] = useState<string[]>([]);
  const [userId, setUserId] = useState<string>("");

  const chatEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Clear the previous session data
    sessionStorage.removeItem("user_id");
  
    // Generate a new user ID and store it in sessionStorage
    const storedUserId = generateUserId();
    sessionStorage.setItem("user_id", storedUserId);
  
    // Set the user ID in state
    setUserId(storedUserId);
  
    // Set initial messages and action options
    const initialMessage = "Welcome! Would you like to Buy, Sell, or Rent?";
    setMessages([{ text: initialMessage, sender: "bot" }]);
    setShowActionButtons(true);
    setActionOptions(["Buy", "Sell", "Rent"]);
  }, []);
  

  const toggleChat = () => {
    setChatVisible(!isChatVisible);
  };

  const handleActionClick = async (action: string) => {
    addMessage(action, "user");
    setShowActionButtons(false);
    setChatMovedLeft(true);
    setMessages([{ text: action, sender: "user" }]);
    await fetchResponse(action.toLowerCase());
  };

  const addMessage = (text: string, sender: "user" | "bot") => {
    setMessages((prev) => [...prev, { text, sender }]);
  };

  const fetchResponse = async (userMessage: string) => {
    try {
      const response = await axios.post("https://bot.dimoora.site/chat", {
        message: userMessage,
        user_id: userId, // Pass the unique user ID to the backend
      });

      const botMessage = response.data.response;
      addMessage(botMessage, "bot");

      if (response.data.options && response.data.options.length > 0) {
        setActionOptions(response.data.options);
        setShowActionButtons(true);
      } else {
        setShowActionButtons(false);
      }
    } catch (error) {
      console.error("Error fetching response from the bot:", error);
      addMessage("Sorry, I could not understand that.", "bot");
    }
  };

  const renderInitialActionButtons = () => {
    return (
      <div className="action-buttons">
        {["Buy", "Sell", "Rent"].map((option, index) => (
          <button
            key={index}
            onClick={() => handleActionClick(option.toLowerCase())}
          >
            <span>{option}</span>
          </button>
        ))}
      </div>
    );
  };

  const renderSubsequentActionButtons = () => {
    return (
      <div className="action-buttons">
        {actionOptions.map((option, index) => (
          <button
            key={index}
            onClick={() => handleActionClick(option.toLowerCase())}
          >
            {option}
          </button>
        ))}
      </div>
    );
  };

  const handleUserMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    if (userMessage.trim()) {
      addMessage(userMessage, "user");
      await fetchResponse(userMessage);
      setUserMessage("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="need-help">Need Help?</div>
      <div className="chatbot-icon" onClick={toggleChat}>
        <img src="/assets/logo.jpeg" alt="Chatbot" />
      </div>
      {isChatVisible && (
        <div id="chat-window" className={isChatMovedLeft ? "move-left" : ""}>
          <header>
            <div>
              <img className="head-img" src="/assets/logo.jpeg" alt="Dimoora" />
              <span className="head">Dimoora</span>
            </div>
            <span className="close-chat" onClick={toggleChat}>
              <i className="fi fi-bs-cross"></i>
            </span>
          </header>
          <div className="chat">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender}`}>
                {message.text}
                <div className="triangle" />
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>
          <footer>
            {showActionButtons &&
              (actionOptions.length > 0
                ? renderSubsequentActionButtons()
                : renderInitialActionButtons())}
            {!showActionButtons && (
              <>
                <input
                  type="text"
                  placeholder="Type your message..."
                  value={userMessage}
                  onChange={handleUserMessageChange}
                  onKeyDown={handleKeyDown}
                />
                <button className="send" onClick={handleSendMessage}>
                  <i className="fi fi-bs-paper-plane-top"></i>
                </button>
              </>
            )}
          </footer>
        </div>
      )}
    </div>
  );
};

export default Chat;

import React from "react";
import "./App.css";
import Routing from "./ROUTING/Routing";
import Chat from './CHATBOT/chat'

function App() {
  return (
    <div className="App">
      <Routing />
      <Chat />

    </div>
  );
}

export default App;
